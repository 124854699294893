import axios from 'axios';
import { errorHandling } from '@/service/helpers.js';
import store from '@/store/index.js';

class OrdersService {
  async getOrders(config) {
    let url = 'panel/api/v1/orders?paginate=1';

    if (config !== undefined) {
      let params = new URLSearchParams(config).toString();
      url = `${url}&${params}`;
    }
    try {
      return await axios.get(url);
    } catch (error) {
      return errorHandling(error);
    }
  }

  async getOrdersDetails(id) {
    let url = `panel/api/v1/orders/${id}`;
    try {
      return await axios.get(url);
    } catch (error) {
      store.commit('setAppNotifications', {
        title: 'Błąd podczas pobierania danych',
        content: 'Błąd podczas pobierania danych',
        type: 'error',
        autoclose: true,
      });
      return errorHandling(error);
    }
  }

  async deleteOrder(id) {
    let url = `panel/api/v1/orders/${id}`;
    try {
      return await axios.delete(url);
    } catch (error) {
      return errorHandling(error);
    }
  }
}

export default new OrdersService();
